import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData, editData, deleteData } from '../../helpers/fackBackend_Helper';

function* updateAggregatorResponse({ payload: { data } }) {
    try {
        const response = yield call(editData, '/updateAggregator', data);
        if (response.Status) {
            yield put(actions.updateAggregatorSuccess(response.Message));
            yield put(actions.getAggregator());
        }
    } catch (error) {
        yield put(actions.addAggregatorFailed(error));
    }
}

function* addAggregatorResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/addAggregator', data);
        if (response.response.Status) {
            yield put(actions.addAggregatorSuccess(response.response.Message));
            yield put(actions.getAggregator());
        }
    } catch (error) {
        yield put(actions.addAggregatorFailed(error));
    }
}

function* getAggregatorResponse() {
    try {
        const response = yield call(getList, '/getAggregatorList');
        if (response.Status) {
            yield put(actions.getAggregatorSuccess(response.Aggregators));
        }
    } catch (error) {
        yield put(actions.addAggregatorFailed(error));
    }
}

function* getAggregatorByNetworkIDResponse({ netId }) {
    try {
        const response = yield call(getList, '/getAggregatorListByNetworkID/' + netId);
        if (response.Status) {
            yield put(actions.getAggregatorByNetworkIDSuccess(response.Aggregators));
        }
    } catch (error) {
        yield put(actions.getAggregatorByNetworkIDFailed(error));
    }
}


function* getActiveAggregatorResponse() {
    try {
        const response = yield call(getList, '/getActiveAggregatorList');
        if (response.Status) {
            yield put(actions.getActiveAggregatorSuccess(response.Data));
        }
    } catch (error) {
        yield put(actions.getActiveAggregatorFailed(error));
    }
}

function* getAggregatorByNetworkIDForPlanResponse({ netId }) {
    try {
        const response = yield call(getList, '/getAggregatorListByNetworkIDForPlan/' + netId);
        if (response.Status) {
            yield put(actions.getAggregatorByNetworkIDForPlanSuccess(response.Aggregators));
        }
    } catch (error) {
        yield put(actions.getAggregatorByNetworkIDForPlanFailed(error));
    }
}


export default function* rootSaga() {
    yield all([takeEvery(actions.ADD_AGGREGATOR, addAggregatorResponse)]);
    yield all([takeEvery(actions.GET_AGGREGATOR, getAggregatorResponse)]);
    yield all([takeEvery(actions.UPDATE_AGGREGATOR, updateAggregatorResponse)]);
    yield all([takeEvery(actions.GET_ACTIVE_AGGREGATOR, getActiveAggregatorResponse)]);
    yield all([takeEvery(actions.GET_AGGREGATOR_BY_NETWORK_ID, getAggregatorByNetworkIDResponse)]);
    yield all([takeEvery(actions.GET_AGGREGATOR_LIST_BY_NETWORK_ID_FOR_PLAN, getAggregatorByNetworkIDForPlanResponse)]);

}