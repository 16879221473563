import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CardLayout from '../../components/utils/CardLayout'
import SearchBar from '../../components/utils/SearchBar';
import SideButtons from '../../components/utils/SideButtons';
import Table from '../../components/utils/Table';
import Modal from '../../components/utils/Model';
import Button from '../../components/utils/Button';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import planAction from '../../redux/plan/actions'
import PencilFill from 'remixicon-react/PencilFillIcon';
import DeleteBin5Fill from 'remixicon-react/DeleteBin5FillIcon';
import LinksLineIcon from 'remixicon-react/LinksLineIcon'
import Popconfirm from '../../components/utils/Popover';
import { Link } from 'react-router-dom';
import Tag from '../../components/utils/Tag';
import moment from 'moment';
import CheckboxCircleFill from 'remixicon-react/CheckboxCircleFillIcon';
import Alert from '../../components/utils/Alert';
import networkActions from '../../redux/network/actions';
import { isAdmin } from '../../helpers/commonFunction';
import authActions from '../../redux/auth/actions';
import Select from 'react-select';
import AggregatorActions from '../../redux/aggregator/actions'


function Plan() {
    const dispatch = useDispatch();
    const { getActiveNetwork } = networkActions;
    const { getUserDataByToken } = authActions;
    const { addPlan, preparePlanForm, resetPlan, getBundleNameData, getPlan, resetPlanMessage, deletePlan, getPlanData, getPlanDay, checkPlanName, openPreferedAggregatorModalPlan, closePreferedAggregatorModalPlan, updatePreffedAggregatorPlan } = planAction;
    const { planFormModal, deleteButtonLoading, planBundleName, planResult, planMessage, bundleError, planError, planNameMessage, loading, planDay, planData, preferedAggregatorModalPlan, planbuttonLoading, planAggregatorError, planAggregatorMessage } = useSelector((state) => state.plan);
    const buttonLoading = useSelector((state) => state.plan.buttonloading)
    const { networkResult } = useSelector((state) => state.network);
    const { tokenData } = useSelector((state) => state.auth);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [filterText, setFilterText] = useState("");
    const [buttonText, setButtonText] = useState();
    const [formTitle, setFormTitle] = useState('');
    const [formButton, setFormButton] = useState('');
    const [editFlag, setEditFlag] = useState(false);
    const [planItemId, setPlanItemId] = useState()
    const [selected, setSelected] = useState([]);
    const [searched, setSearched] = useState(false);
    const [planRechargeId, setPlanRechargeId] = useState(0);
    const [networkId, setNetworkId] = useState(0);

    const { list, listGetByNetworkId, aggregatorListByNetworkIdForPlan } = useSelector((state) => state.aggregator);
    const { getAggregator, getAggregatorByNetworkID, getAggregatorByNetworkIDForPlan } = AggregatorActions;


    const [planFromData, setPlanFromData] = useState({
        planID: "",
        name: "",
        planDesc: "",
        planPrice: "",
        networkID: "",
        // tMobilePlanID: "",
        aggregatorData: [],
    })

    const [planSearchData, setplanSearchData] = useState({
        network: "",
    })

    const emptyData = {
        planID: "",
        name: "",
        planDesc: "",
        planPrice: "",
        networkID: "",
        // tMobilePlanID: ""
    }
    const { register, handleSubmit, formState: { errors }, watch, reset: resetForm, control, setValue, } = useForm({
        defaultValues: planFromData,
    });
    const { fields, append, remove, replace } = useFieldArray({
        name: "aggregatorData",
        control,
    });

    const { register: registerregister, handleSubmit: handleplanSubmit, formState: { errors: errorerror }, control: searchControl } = useForm({
        defaultValues: planSearchData,
    });
    const [prefferedAggregatorForm, setPrefferedAggregatorForm] = useState({
        PrefferedAggregatorId: "",
        // RechargeAggregatorId: ""
    });

    const { register: registerReset, handleSubmit: handleSubmitReset, watch: watchReset, formState: { errors: errorsReset }, reset: resetReset } = useForm({
        defaultValues: prefferedAggregatorForm
    });

    let columns = isAdmin(tokenData) ? [
        { displayName: '#', key: 'key' },
        { displayName: 'PLAN NAME', key: 'planName' },
        { displayName: 'PLAN PRICE', key: 'planPrice' },
        { displayName: 'NETWORK', key: 'network' },
        { displayName: 'PREFERRED AGGREGATOR', key: 'preferredAggregator' },
        { displayName: 'PLAN DESCRIPTION', key: 'planDesc' },
        { displayName: 'ACTION', key: 'action' }
    ] : [
        { displayName: '#', key: 'key' },
        { displayName: 'PLAN NAME', key: 'planName' },
        { displayName: 'PLAN PRICE', key: 'planPrice' },
        { displayName: 'CHILD PRICE', key: 'ChildPrice' },
        { displayName: 'NETWORK', key: 'network' },
        { displayName: 'PLAN DESCRIPTION', key: 'planDesc' },
    ];
    columns = columns.filter(column => column !== null);


    useEffect(() => {
        if (aggregatorListByNetworkIdForPlan && aggregatorListByNetworkIdForPlan.length > 0) {
            resetAggregatorData(aggregatorListByNetworkIdForPlan);
        }
    }, [aggregatorListByNetworkIdForPlan]);

    const resetAggregatorData = (aggregators) => {
        const defaultAggregatorData = aggregators.map((item) => ({
            aggregatorID: item.ID,
            key: item.AggregatorName,
            value: '',
        }));
        setValue('aggregatorData', defaultAggregatorData);
    };

    const handleNetworkChange=(e)=>{
        const networkid=e.target.value
        dispatch(getAggregatorByNetworkIDForPlan(networkid));
    }

    useEffect(() => {
        dispatch(getActiveNetwork());
        dispatch(getUserDataByToken());
        dispatch(getAggregator());
    }, [])

    useEffect(() => {
        if (editFlag === true) {
            resetForm(planFromData);
        }
    }, [planFromData, planBundleName, editFlag]);

    useEffect(() => {
        if (editFlag === false) {
            resetForm(planFromData);
        }
    }, [planFromData])

    useEffect(() => {
        if (listGetByNetworkId && listGetByNetworkId.length > 0 && networkId != 0) {
            // let selectedRow = networkResult.filter((item) => item.ID == networkId);
            let selectedRow = planResult.filter((item) => item.ID == planRechargeId);
            if (selectedRow.length > 0) {
                resetReset({
                    PrefferedAggregatorId: selectedRow[0].PrefferedAggregator ? selectedRow[0].PrefferedAggregator.toString() : "",
                });
            }
        }
    }, [listGetByNetworkId, planRechargeId]);

    useEffect(() => {
        if (planError !== null && planError !== undefined) {
            displayAlert(planError, 'alert-danger');
        }
        if (planMessage !== null && planMessage !== undefined) {
            displayAlert(planMessage, 'alert-success');
        }

        if (planAggregatorError !== null && planAggregatorError !== undefined) {
            displayAlert(planAggregatorError, 'alert-danger');
        }
        if (planAggregatorMessage !== null && planAggregatorMessage !== undefined) {
            displayAlert(planAggregatorMessage, 'alert-success');
        }

        if (planNameMessage !== null && planNameMessage !== undefined) {
            if (planNameMessage === 0) {
                // setValue('name', '')
                // setNameMessage('Plan name already exist !')
            } else {
                // setNameMessage('')
                dispatch(resetPlanMessage());
            }
        }
    }, [planError, planMessage, planNameMessage, planAggregatorMessage, planAggregatorError]);

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetPlanMessage());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetPlanMessage());
    }
    const options = networkResult.map(network => ({
        label: network.NetworkName,
        value: network.ID,
    }));
    const filteredItems = planResult && planResult.length > 0 && planResult.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );
    const renderPlanTable = () => {
        if (searched) {
            return (
                <Table columns={columns} data={dataSource} loading={loading} pagination={true} />
            );
        } else {
            return null;
        };
    }

    const dataSource = filteredItems && filteredItems.length > 0 && filteredItems.map((plan, key) => {
        const aggregator = list && list.length > 0 && list.filter(item => item.ID == plan.PrefferedAggregator)
        // const truncatedDesc = plan.PlanDesc && plan.PlanDesc.length > 60 ? plan.PlanDesc.substring(0, 60) + '...' : plan.PlanDesc;
        var actionContent = (
            (isAdmin(tokenData) && <>
                <Link to="#">
                    <span data-toggle="tooltip" data-placement="top" title="Edit">
                        <PencilFill size="1.2em" style={{ color: "#888b8e", marginRight: "3px" }} onClick={() => handleOpenEditFormModal(plan.ID)} />
                    </span>
                </Link>
                {deleteButtonLoading && planItemId === plan.ID ? <span className="spinner-border spinner-border-sm" role="status"></span> : plan.IsActive === 1 ? (
                    <Popconfirm
                        title="Are you sure to inactive this plan?"
                        okText="Yes"
                        cancelText="No"
                        placement="topRight"
                        onConfirm={() => handleDelete(plan.ID, 0)}
                    >
                        <Link className="deleteBtn" to="# ">
                            <span data-toggle="tooltip" data-placement="top" title="Inactive">
                                <DeleteBin5Fill size="1.2em" style={{ color: "#f14336", marginRight: "3px" }} />
                            </span>
                        </Link>
                    </Popconfirm>

                ) : (
                    <Popconfirm
                        title="Are you sure to active this plan?"
                        okText="Yes"
                        cancelText="No"
                        placement="topRight"
                        onConfirm={() => handleDelete(plan.ID, 1)}
                    >
                        <Link className="deleteBtn" to="# ">
                            <span data-toggle="tooltip" data-placement="top" title="Active">
                                <CheckboxCircleFill size="1.2em" style={{ color: "#2cb549", marginRight: "3px" }} />
                            </span>
                        </Link>
                    </Popconfirm>

                )}
                {/* <Link to="#"
                    onClick={() => handleShowDealerDetails(plan.ID, plan)}
                >
                    <EyeFill size="1.2em" style={{ color: "#0094b3" }} />
                </Link> */}
                <Link to="#" onClick={() => handlepreferredAggregatorModal(plan.NetworkID, plan.ID)}>
                    <span data-toggle="tooltip" data-placement="top" title="Manage aggregator">
                        <LinksLineIcon size="1.2em" style={{ color: "#0094b3", marginRight: "3px" }} />
                    </span>
                </Link>
            </>)
        );

        return {
            key: key + 1,
            planName: plan.PlanName,
            planName: plan.PlanName,
            ChildPrice: `$${plan.ChildPrice}`,
            planPrice: `$${plan.PlanPrice}`,
            planDesc: <span className="wrapped-text" title={plan.PlanDesc}>{plan.PlanDesc}</span>,
            preferredAggregator: aggregator && aggregator.length > 0 ? aggregator[0].AggregatorName : '',
            // tMobilePlanId: plan.TMobilePlanID,
            network: plan.NetworkName,
            createDate: plan.CreatedDtTm !== null ? moment(new Date(plan.CreatedDtTm)).format(`${process.env.REACT_APP_DATE_FORMAT}`) : 'N/A',
            status: <Tag color={`${plan.IsActive === 1 ? "badge-success" : "badge-danger"}`} title={plan.IsActive === 1 ? 'Active' : 'Inactive'} />,
            action: actionContent,
        }
    });

    const handlepreferredAggregatorModal = (id, planId) => {
        dispatch(getAggregatorByNetworkID(id));
        setFormTitle('Manage Aggregator')
        setButtonText('Submit');
        setNetworkId(id);
        dispatch(openPreferedAggregatorModalPlan());
        setPlanRechargeId(planId)

    }

    const handleCancelModal = () => {
        dispatch(closePreferedAggregatorModalPlan());
    };

    const handleUpdatePrefferedAggregator = (data) => {
        data.PlanId = planRechargeId;
        dispatch(updatePreffedAggregatorPlan(data, networkId));
    }

    const handleFilterChange = (value) => {
        setFilterText(value);
    };
    const handleOpenAddFormModal = () => {
        resetAggregatorData([]);
        dispatch(preparePlanForm());
        setPlanFromData(emptyData);
        setFormButton('Submit')
        setFormTitle('Add Plan')
    }
    const handleOpenEditFormModal = (ID) => {
        dispatch(preparePlanForm());
        setFormTitle("Edit Plan");
        setFormButton('Update');
        setEditFlag(true);
        var selectedPlan = planResult.filter(plan => plan.ID === ID);
        let newAggregatorData = [
            {
                aggregatorID: 1,
                key: 'ENK Wireless',
                value: selectedPlan[0].TMobilePlanID
            },
            {
                aggregatorID: 3,
                key: 'PrepaidIQ',
                value: selectedPlan[0].PrepaidIQPlanID
            },
            {
                aggregatorID: 4,
                key: 'ENK Boom',
                value: selectedPlan[0].BoomPlanID
            },
        ]
        newAggregatorData = newAggregatorData.filter(item => item.value != 0 && item.value != null)
        setPlanFromData(prevState => ({
            ...prevState,
            planID: selectedPlan[0].ID,
            name: selectedPlan[0].PlanName,
            planPrice: selectedPlan[0].PlanPrice,
            planDesc: selectedPlan[0].PlanDesc,
            networkID: selectedPlan[0].NetworkID,
            // tMobilePlanID: selectedPlan[0].TMobilePlanID,
            aggregatorData: newAggregatorData
        }));
    }
    const handleUpdate = (data) => {
        dispatch(addPlan(data));
    }
    const onSubmit = (data) => {
        dispatch(addPlan(data));
    }
    const handleCancel = () => {
        setEditFlag(false)
        resetForm({
            planID: "",
            name: "",
            planDesc: "",
            planPrice: "",
            networkID: "",
            // tMobilePlanID: "",
            aggregatorData: [],
        });
        resetAggregatorData([]);
        dispatch(resetPlan());
    }
    const handleDelete = (ID, flag) => {
        setPlanItemId(ID)
        dispatch(deletePlan(ID, flag));
    }

    const onPlanSubmit = (data) => {
        setSearched(true);
        dispatch(getPlan(data.network.value));
    }
    return (
        <CardLayout title="Plan">
            {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
            <form onSubmit={handleplanSubmit(onPlanSubmit)}>
                <div className="form-row align-items-end">
                    <div className="col-lg-3 col-md-3  mb-3p-0">
                        <label>Network</label>
                        <Controller
                            control={searchControl}
                            name="network" {...registerregister("network", {
                                required: true,
                            })}
                            // rules={{
                            //     required: true,
                            // }}
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                            }) => (
                                <Select
                                    options={options}
                                    onChange={onChange}
                                    // defaultValue={aggregatorFrom.network}
                                    // isMulti={true}
                                    onBlur={onBlur}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                />
                            )}
                        />
                        {errorerror.network?.type === "required" && (<div class="invalid-feedback">Network required!</div>)}
                    </div>
                    <div className="col-md-2 mt-lg-4 mt-2">

                        <Button title="Search" style="minWidth:80px" buttonClass={` btn btn-primary mt-1 btn-md Activity_searchBtn__GlBtC`} buttonType='submit' />

                    </div>
                </div>
            </form>
            {searched ?
                <div className="row align-items-baseline">
                    <div className='col-lg-6 col-md-6 mt-3  od-2'>
                        <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                    </div>
                    <div className='col-lg-6 col-md-6 mt-3 text-right'>
                        {isAdmin(tokenData) && <SideButtons title="Add Plan" buttonStyle="primary" onClick={handleOpenAddFormModal} />}
                    </div>
                </div>
                : ""}
            {renderPlanTable()}
            <Modal
                showModal={planFormModal}
                title={formTitle}
                onHide={handleCancel}
                width={700}
                showFooter={false}
            >
                <form onSubmit={handleSubmit(editFlag ? handleUpdate : onSubmit)}>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label>Plan Name</label>
                            <input type="text" placeholder="Enter Plan Name" className="form-control"
                                name="name" {...register("name", {
                                    required: true,
                                })} />
                            {errors.name?.type === "required" && (
                                <div className="invalid-feedback">Plan name is required !</div>

                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Plan Price</label>
                            <input type="number" className="form-control" placeholder="Enter Plan Price" onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} name="planPrice" {...register("planPrice", {
                                required: true,
                            })} />
                            {errors.planPrice?.type === "required" && (
                                <div className="invalid-feedback">Plan Price is required !</div>
                            )}
                        </div>

                        {/* <div className="col-md-6 mb-3">
                            <label>Plan ID</label>
                            <input className="form-control" placeholder="Enter Plan ID" onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} name="tMobilePlanID" {...register("tMobilePlanID", {
                                required: true,
                            })} />
                            {errors.tMobilePlanID?.type === "required" && (
                                <div className="invalid-feedback">Plan id is required !</div>
                            )}
                        </div> */}
                        <div className="col-md-6 mb-3">
                            <label>Network</label>
                            <select className="form-control" placeholder="Select Network" name="networkID"  {...register("networkID", {
                                required: true,
                            })} onChange={handleNetworkChange}>
                                <option value="" key="0">Select</option>
                                {networkResult.length > 0 && networkResult.map(network => (
                                    <option name="network" value={network.ID} key={network.ID}>{network.NetworkName}</option>
                                ))}
                            </select>
                            {errors.networkID?.type === "required" && (
                                <div className="invalid-feedback">Network is required !</div>
                            )}
                        </div>

                        {fields && fields.length ?
                            <div className="col-md-12">
                                <p className={``} style={{ fontWeight: '600', color: '#3f414d' }}>Aggregator Data</p>
                                <hr />
                            </div> : ''}

                        {fields.map((field, index) => (
                            <div className="col-md-6 mb-3" key={field.id}>
                                <label>{field.key}</label>
                                <input type="text" className="form-control" placeholder="Aggregator Plan ID"
                                    {...register(`aggregatorData.${index}.value`, { required: "Value is required !" })} />
                                {errors.aggregatorData?.[index]?.value && (
                                    <div className="invalid-feedback">{errors.aggregatorData[index].value.message}</div>
                                )}
                            </div>
                        ))}


                        <div className="col-md-12 mb-3">
                            <label>Description</label>
                            <textarea className="form-control" placeholder="Enter Description" id="planDesc" name="planDesc" rows="3" cols="30" {...register("planDesc", {
                                required: true,
                            })}>
                            </textarea>
                            {errors.planDesc?.type === "required" && (
                                <div className="invalid-feedback">Description is required !</div>
                            )}
                        </div>
                    </div>
                    <hr />
                    <div className='text-right row'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                            <Button title={formButton} style="minWidth:80px" buttonClass="btn btn-primary" loading={buttonLoading} buttonType='submit' />
                        </div>
                    </div>
                </form>
            </Modal>

            <Modal
                showModal={preferedAggregatorModalPlan}
                title={formTitle}
                onHide={handleCancelModal}
                width={550}
                showFooter={false}>

                <form onSubmit={handleSubmitReset(handleUpdatePrefferedAggregator)}>
                    <div className="col-md-12 mb-3 p-0">
                        <label>Activation Aggregator</label>
                        <select className="form-control form-select" placeholder="Select Aggregator" name="PrefferedAggregatorId"
                            {...registerReset('PrefferedAggregatorId', { required: true })} >
                            <option value="" key="0" disabled>Select Aggregator</option>
                            {
                                listGetByNetworkId && listGetByNetworkId.length && listGetByNetworkId.map((item, index) => {
                                    return <option value={item.ID} key={`${index}0`}> {item.AggregatorName}</option>
                                })
                            }
                        </select>
                        {errorsReset.PrefferedAggregatorId?.type === "required" && (<div className="invalid-feedback">Activation Aggregator is required !</div>)}
                    </div>
                    <div className='text-right row my-2'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={handleCancelModal}>Close</button>
                            <Button title={buttonText} loading={planbuttonLoading} buttonClass="btn btn-primary" buttonType='submit' />
                        </div>
                    </div>
                </form>


            </Modal>


        </CardLayout>
    )
}

export default Plan